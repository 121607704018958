var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidaCampo',{attrs:{"dadosOriginais":_vm.RADA.tsRada,"novoHistorico":_vm.novoHistorico.tsRada,"campoValue":"titulo","campoText":"Título Tabela Seleção","tipo":"string"},scopedSlots:_vm._u([{key:"input",fn:function(props){return [_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'Campo obrigatório'; }],"solo":""},on:{"input":props.items.updateValue},model:{value:(props.items.campoEditado),callback:function ($$v) {_vm.$set(props.items, "campoEditado", $$v)},expression:"props.items.campoEditado"}})]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"clearable":"","prepend-inner-icon":"search","label":"Pesquise a classe..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"flat":"","headers":_vm.headers,"items":_vm.novoHistorico.tsRada.classes.dados,"footer-props":_vm.footer_props,"items-per-page":5,"item-key":"codigo","custom-filter":function (value, search, item) {
          return !!JSON.stringify(item.dados).includes(search) ? true : false;
        },"search":_vm.search},on:{"click:row":_vm.validarClasse},scopedSlots:_vm._u([{key:"item.tipo",fn:function(ref){
        var item = ref.item;
return [(item.dados.tipo.dados == 'Série')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_sr}}):(item.dados.tipo.dados == 'Subsérie')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_ssr}}):(item.dados.tipo.dados == 'N1')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_N1}}):(item.dados.tipo.dados == 'N2')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_N2}}):(item.dados.tipo.dados == 'N3')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_N3}}):_c('i',[_vm._v(_vm._s(item.tipo))])]}},{key:"item.codigo",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.dados.codigo.dados))]}},{key:"item.titulo",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.dados.titulo.dados))]}},{key:"item.df",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(!!item.dados.df ? item.dados.df.dados : ''))]}},{key:"item.eFilhoDe",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(!!item.dados.eFilhoDe.dados ? item.dados.eFilhoDe.dados : ''))]}},{key:"item.pca",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(!!item.dados.pca ? item.dados.pca.dados : ''))]}},{key:"item.validade",fn:function(ref){
        var item = ref.item;
return [(item.cor == 'verde')?_c('v-avatar',{attrs:{"size":"25","color":"green"}}):(item.cor == 'vermelho')?_c('v-avatar',{attrs:{"size":"25","color":"red"}}):(item.cor == 'amarelo')?_c('v-avatar',{attrs:{"size":"25","color":"amber"}}):_vm._e()]}}])})],1)],1),(_vm.tipoClasse == 'N1' || _vm.tipoClasse == 'N2' || _vm.tipoClasse == 'N3')?_c('AnalisaArea',{attrs:{"elemento":_vm.classeOriginal,"novoHistorico":_vm.classeHistorico},on:{"fechar":_vm.fechar}}):_vm._e(),(_vm.tipoClasse == 'Série' || _vm.tipoClasse == 'Subsérie')?_c('AnalisaSerie',{attrs:{"elemento":_vm.classeOriginal,"novoHistorico":_vm.classeHistorico,"formaContagem":_vm.formaContagem},on:{"fechar":_vm.fechar}}):_vm._e(),(_vm.novoHistorico.tsRada.UIs.dados[0])?_c('div',[_c('h5',[_vm._v("Unidades de instalação")]),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"clearable":"","prepend-inner-icon":"search","label":"Pesquise a unidade de instalação..."},model:{value:(_vm.searchUI),callback:function ($$v) {_vm.searchUI=$$v},expression:"searchUI"}}),_c('v-data-table',{attrs:{"headers":_vm.headersUI,"items":_vm.novoHistorico.tsRada.UIs.dados,"footer-props":_vm.footer_props,"items-per-page":5,"custom-filter":function (value, search, item) {
          return !!JSON.stringify(item.dados).includes(search) ? true : false;
        },"search":_vm.searchUI},on:{"click:row":_vm.validarUI},scopedSlots:_vm._u([{key:"item.validade",fn:function(ref){
        var item = ref.item;
return [(item.cor == 'verde')?_c('v-avatar',{attrs:{"size":"25","color":"green"}}):(item.cor == 'vermelho')?_c('v-avatar',{attrs:{"size":"25","color":"red"}}):(item.cor == 'amarelo')?_c('v-avatar',{attrs:{"size":"25","color":"amber"}}):_vm._e()]}},{key:"item.codigo",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.dados.codigo.dados))]}},{key:"item.titulo",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.dados.titulo.dados))]}},{key:"item.classesAssociadas",fn:function(ref){
        var item = ref.item;
return [(!!item.dados.classesAssociadas.dados[0])?_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item.dados.classesAssociadas.dados),function(classe,i){return _c('li',{key:i},[_c('span',{staticStyle:{"padding-right":"10px"}},[(classe.tipo == 'Série')?_c('img',{staticStyle:{"width":"23px","height":"23px"},attrs:{"src":_vm.svg_sr}}):_c('img',{staticStyle:{"width":"23px","height":"23px"},attrs:{"src":_vm.svg_ssr}})]),_c('span',[_vm._v(_vm._s(classe.codigo))])])}),0):_c('p',[_c('br'),_vm._v("Não tem séries/subséries associadas! ")])]}}],null,false,2254188184)})],1)],1)],1):_vm._e(),(_vm.tipoClasse == 'UI')?_c('AnalisaUI',{attrs:{"elemento":_vm.classeOriginal,"novoHistorico":_vm.classeHistorico},on:{"fechar":_vm.fechar}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }