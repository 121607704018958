var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-1"},[(_vm.loading)?_c('Loading',{attrs:{"message":'pedido'}}):_c('v-row',{staticClass:"ma-1"},[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"indigo darken-4 title white--text mb-5",attrs:{"dark":""}},[_vm._v(" Validação do pedido: "+_vm._s(_vm.pedido.codigo)+" - "+_vm._s(_vm.pedido.objeto.acao)+" de "+_vm._s(_vm.pedido.objeto.tipo)+" "),_c('v-spacer'),(
              _vm.temPermissaoConsultarHistorico() &&
              !(
                _vm.pedido.objeto.acao === 'Criação' &&
                (_vm.pedido.estado === 'Submetido' || _vm.pedido.estado === 'Ressubmetido')
              )
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-4",attrs:{"color":"white"},on:{"click":function($event){return _vm.verHistorico()}}},on),[_vm._v("history")])]}}],null,false,3127590612)},[_c('span',[_vm._v("Ver histórico de alterações...")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":"white"},on:{"click":function($event){return _vm.showDespachos()}}},on),[_vm._v("comment")])]}}])},[_c('span',[_vm._v("Ver despachos...")])])],1),(_vm.pedido.objeto.acao === 'Criação' || _vm.pedido.objeto.acao === 'Importação')?_c('v-card-text',[(_vm.pedido.objeto.tipo === 'Entidade')?_c('ValidaEntidade',{attrs:{"p":_vm.pedido}}):_vm._e(),(_vm.pedido.objeto.tipo === 'RADA')?_c('ValidaRADA',{attrs:{"p":_vm.pedido,"fase":"validacao"}}):_vm._e(),(_vm.pedido.objeto.tipo === 'Legislação')?_c('ValidaLegislacao',{attrs:{"p":_vm.pedido}}):_vm._e(),(_vm.pedido.objeto.tipo === 'Tipologia')?_c('ValidaTipologiaEntidade',{attrs:{"p":_vm.pedido}}):(
              _vm.pedido.objeto.tipo === 'Classe_N3' ||
              _vm.pedido.objeto.tipo === 'Classe_N1' ||
              _vm.pedido.objeto.tipo === 'Classe_N2'
            )?_c('ValidaClasseN1',{attrs:{"p":_vm.pedido,"validar":""}}):_vm._e(),(
              _vm.pedido.objeto.tipo.includes('AE ') ||
              _vm.pedido.objeto.tipo === 'Auto de Eliminação'
            )?_c('ValidaAE',{attrs:{"p":_vm.pedido,"tipo":_vm.pedido.objeto.tipo}}):_vm._e(),(_vm.pedido.objeto.tipo == 'TS Pluriorganizacional')?_c('ValidaTSPluri',{attrs:{"p":_vm.pedido,"fase":"validacao"}}):_vm._e(),(_vm.pedido.objeto.tipo == 'TS Organizacional')?_c('ValidaTSOrg',{attrs:{"p":_vm.pedido,"fase":"validacao"}}):_vm._e(),(_vm.pedido.objeto.tipo == 'PPD')?_c('ValidaPPD',{attrs:{"p":_vm.pedido,"fase":"validacao"}}):_vm._e()],1):_vm._e(),(
            _vm.pedido.objeto.acao === 'Alteração' ||
            _vm.pedido.objeto.acao === 'Extinção' ||
            _vm.pedido.objeto.acao === 'Revogação'
          )?_c('v-card-text',[_c('span',[_c('v-alert',{staticClass:"m-auto mb-2 mt-2",attrs:{"type":"info","width":"90%","outlined":""}},[(_vm.pedido.objeto.tipo === 'Legislação')?_c('span',[_c('b',[_vm._v(_vm._s(_vm.pedido.objeto.tipo)+":")]),_vm._v(" "+_vm._s(_vm.pedido.objeto.dadosOriginais.diplomaFonte)+" - "+_vm._s(_vm.pedido.objeto.dadosOriginais.numero)+" - "+_vm._s(_vm.pedido.objeto.dadosOriginais.sumario)+" ")]):(
                  _vm.pedido.objeto.tipo === 'Entidade' ||
                  _vm.pedido.objeto.tipo === 'Tipologia'
                )?_c('span',[_c('b',[_vm._v(_vm._s(_vm.pedido.objeto.tipo)+":")]),_vm._v(" "+_vm._s(_vm.pedido.objeto.dadosOriginais.sigla)+" - "+_vm._s(_vm.pedido.objeto.dadosOriginais.designacao)+" ")]):_vm._e()]),_c('v-divider',{staticClass:"m-auto mb-2"})],1),(_vm.pedido.objeto.tipo === 'Entidade')?_c('ValidaEditaEntidade',{attrs:{"p":_vm.pedido}}):_vm._e(),(_vm.pedido.objeto.tipo === 'Legislação')?_c('ValidaEditaLegislacao',{attrs:{"p":_vm.pedido}}):_vm._e(),(_vm.pedido.objeto.tipo === 'Tipologia')?_c('ValidaEditaTipologiaEntidade',{attrs:{"p":_vm.pedido}}):_vm._e()],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":"50%","persistent":""},model:{value:(_vm.erroDialog.visivel),callback:function ($$v) {_vm.$set(_vm.erroDialog, "visivel", $$v)},expression:"erroDialog.visivel"}},[_c('ErroDialog',{attrs:{"erros":_vm.erroDialog.mensagem,"uri":"/pedidos"}})],1),_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.despachosDialog),callback:function ($$v) {_vm.despachosDialog=$$v},expression:"despachosDialog"}},[_c('VerDespachos',{attrs:{"despachos":_vm.pedido.distribuicao},on:{"fecharDialog":function($event){return _vm.fecharDialog()}}})],1),_c('v-dialog',{attrs:{"width":"90%"},model:{value:(_vm.verHistoricoDialog),callback:function ($$v) {_vm.verHistoricoDialog=$$v},expression:"verHistoricoDialog"}},[_c('VerHistorico',{attrs:{"pedido":_vm.pedido},on:{"fecharDialog":function($event){return _vm.fecharHistorico()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }