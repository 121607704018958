var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-2 text-center"},[_c('v-col',[(_vm.temPermissaoDevolver())?_c('v-btn',{staticClass:"red darken-4",attrs:{"dark":"","rounded":""},on:{"click":function($event){_vm.devolverPedidoDialog = true}}},[_vm._v(" Devolver ")]):_vm._e()],1),_c('v-col',[(
          _vm.operacao === 'Analisar' ||
          _vm.pedidoAuxiliar.estado === 'Apreciado' ||
          _vm.pedidoAuxiliar.estado === 'Apreciado2v' || 
          _vm.pedidoAuxiliar.estado === 'Reapreciado' ||
          _vm.pedidoAuxiliar.estado === 'Reapreciado2v'
        )?_c('v-btn',{staticClass:"indigo accent-4 white--text",attrs:{"rounded":""},on:{"click":function($event){_vm.avancarPedidoDialog = true}}},[_vm._v(" Encaminhar ")]):_vm._e()],1),_c('v-col',[(_vm.operacao === 'Validar')?_c('v-btn',{staticClass:"indigo accent-4 white--text",attrs:{"rounded":""},on:{"click":function($event){_vm.finalizarPedidoDialog = true}}},[_vm._v(" Finalizar ")]):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"60%"},model:{value:(_vm.avancarPedidoDialog),callback:function ($$v) {_vm.avancarPedidoDialog=$$v},expression:"avancarPedidoDialog"}},[_c('AvancarPedido',{attrs:{"utilizadores":_vm.utilizadores,"texto":{
        textoTitulo: 'Distribuição',
        textoAlert: 'validação',
        textoBotao: 'Distribuir',
      },"pedido":this.$route.params.idPedido},on:{"fecharDialog":function($event){return _vm.fecharDialog()},"avancarPedido":function($event){return _vm.avancarPedido($event)}}})],1),_c('v-dialog',{attrs:{"width":"60%"},model:{value:(_vm.devolverPedidoDialog),callback:function ($$v) {_vm.devolverPedidoDialog=$$v},expression:"devolverPedidoDialog"}},[_c('DevolverPedido',{on:{"fecharDialog":function($event){return _vm.fecharDialog()},"devolverPedido":function($event){return _vm.devolverPedido($event)}}})],1),_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.finalizarPedidoDialog),callback:function ($$v) {_vm.finalizarPedidoDialog=$$v},expression:"finalizarPedidoDialog"}},[_c('FinalizarPedido',{attrs:{"vai_para_despacho":_vm.vai_para_despacho},on:{"fecharDialog":function($event){return _vm.fecharDialog()},"finalizarPedido":function($event){return _vm.finalizarPedido($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }